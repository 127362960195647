import React from 'react';

import { ArrowLeftOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { Link } from 'gatsby';
import LocalizedStrings from 'react-localization';

import { ClientHelmet } from '../components';
import { FormPageLayout } from '../components/Layouts';

const { Title } = Typography;
const strings = new LocalizedStrings({
    en: {
        notFound: 'Oops! Page not found',
        backToEvent: 'Back to event',
    },
    de: {
        notFound: 'Oops! Die Seite wurde nicht gefunden',
        backToEvent: 'Zurück zum Event',
    },
});

const NotFoundPage = () => (
    <FormPageLayout>
        <ClientHelmet />
        <Title level={2} style={{ marginBottom: '0', color: 'white' }}>
            {strings.notFound}
        </Title>
        <Link style={{ color: 'white' }} to={`/${typeof window !== 'undefined' ? window.location.search : ''}`}>
            <ArrowLeftOutlined />
            {strings.backToEvent}
        </Link>
    </FormPageLayout>
);

export default NotFoundPage;
